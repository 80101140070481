













































































































































































import { Component, Vue } from "vue-property-decorator";

import Graph from "@/components/Report/Graph.vue";
import GraphEvo from "@/components/Report/GraphEvo.vue";
import GraphEach from "@/components/Report/GraphEach.vue";
import GraphAVG from "@/components/Report/GraphAVG.vue";
import GraphCompare from "@/components/Report/GraphCompare.vue";

@Component({
  components: {
    Graph,
    GraphEvo,
    GraphEach,
    GraphAVG,
    GraphCompare,
  },
  computed: {},
  props: {
    color: {
      default: "light",
      validator: function (value) {
        return ["light", "dark"].indexOf(value) !== -1;
      },
    },
  },
})
export default class Table extends Vue {
  private tab: number = 0;

  private async created() {
    await this.defaultTab();
  }

  async defaultTab() {
    let path: number = Number(this.$route.query.tab);
    return path ? await this.toggleTabs(path) : null;
  }
  async toggleTabs(tabNumber: number) {
    console.log(this.$route.fullPath);
    if (this.$route.query.tab != String(tabNumber)) {
      await this.$router.push({
        query: {
          tab: String(tabNumber),
        },
      });
    }
    this.tab = tabNumber;
  }
}
